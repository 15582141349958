class ZIDXAccountResetPassword implements ZIDXPage{
    constructor() {
    }
    getPageClass(){
        return "zidxAccountResetPasswordContainer";
    }
    getMatchURL(){
        return "/reset-password";
    }
    render(){
        // console.log("reset password");
    }
}
